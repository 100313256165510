import React, { useState } from "react";
import {
  Backdrop,
  Fade,
  Modal,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Button,
  withStyles,
  Box,
  makeStyles,
  Typography,
  FormHelperText,
  Snackbar,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { bool, func } from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import signUpSuccessImg from "../../../assets/images/sign-up-success.svg";
import googleIcon from "../../../assets/images/google-icon.svg";
import facebookIcon from "../../../assets/images/facebook-icon.svg";
import usersApi from "../../../apis/usersApi";
import { ALERT_TYPES, MODAL_TYPES } from "../../../utils/constants";
import {
  validateEmail,
  validatePhone,
  isBrowser,
  isMobile,
} from "../../../utils/common";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  signInModal: {
    backgroundColor: "#f7f8f9",
    width: 768,
    maxHeight: "92%",
    borderRadius: 16,
    margin: "auto",
    outline: 0,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100vh",
      borderRadius: 0,
      maxHeight: "100%",
    },
  },
  header: {
    display: "flex",
    width: "100%",
    padding: "9px 0",
    backgroundColor: "#f7f8f9",
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    minHeight: 30,
  },
  logo: {
    padding: "20px 0",
    textAlign: "center",
    backgroundColor: "#ffffff",
  },
  form: {
    backgroundColor: "#f7f8f9",
    padding: "24px 25%",
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    [theme.breakpoints.down("sm")]: {
      padding: "24px 8%",
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 400,
    margin: 0,
    textTransform: "uppercase",
    marginBottom: 8,
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 400,
    margin: 0,
    marginBottom: 16,
    color: "#4F4F4F",
  },
  remember: {
    padding: "16px 0",
  },
  newPassword: {
    paddingTop: 16,
    textDecoration: "underline",
    fontSize: 14,
    textAlign: "center",
    cursor: "pointer",
  },
  or: {
    padding: "16px 0",
    fontSize: 14,
    textTransform: "capitalize",
    textAlign: "center",
    position: "relative",
    "&::before": {
      content: '""',
      borderBottom: "1px solid #E0E0E0",
      width: "35%",
      position: "absolute",
      bottom: 25,
      left: 0,
    },
    "&::after": {
      content: '""',
      borderBottom: "1px solid #E0E0E0",
      width: "35%",
      position: "absolute",
      bottom: 25,
      right: 0,
    },
  },
  hr: {
    width: "100%",
    margin: "30px 0",
    border: "1px solid #E0E0E0",
  },
  formControl: {
    marginBottom: 8,
  },
  firstName: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  lastName: {
    flex: 1,
    marginLeft: theme.spacing(1),
  },
  inputLabel: {
    fontSize: 14,
    color: "#000000",
  },
  sendNow: {
    marginTop: 40,
  },
  iconBack: {
    fontSize: 14,
    marginBottom: theme.spacing(5),
  },
  backText: {
    flexGrow: 1,
    fontSize: 14,
    marginLeft: 20,
  },
}));

const Close = withStyles(() => ({
  root: {
    marginLeft: "auto",
    paddingRight: "18px",
    cursor: "pointer",
  },
}))(Box);

const SignInButton = withStyles(() => ({
  root: {
    color: "#ffffff",
    backgroundColor: "#000000",
    "&:hover": {
      backgroundColor: "#000000",
    },
    height: 50,
    borderRadius: "140px",
  },
}))(Button);

const SignInWithGoogleButton = withStyles(() => ({
  root: {
    height: 50,
    paddingLeft: "10px",
    color: "#000000",
    textTransform: "capitalize",
    fontWeight: 600,
    backgroundColor: "#ffffff",
    borderRadius: "140px",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
  },
}))(Button);

const SignInWithFacebookButton = withStyles(() => ({
  root: {
    height: 50,
    paddingLeft: "10px",
    color: "#000000",
    textTransform: "capitalize",
    fontWeight: 600,
    backgroundColor: "#ffffff",
    borderRadius: "140px",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
  },
}))(Button);

const SignUpButton = withStyles(() => ({
  root: {
    color: "#000000",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
    height: 50,
    borderRadius: "140px",
  },
}))(Button);

const ImageSignUpSuccess = withStyles(() => ({
  root: {
    textAlign: "center",
    marginBottom: 30,
    minHeight: 160,
  },
}))(Box);

const SelectRole = withStyles(() => ({
  root: {
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
}))(Select);

const CustomInput = withStyles(() => ({
  root: {
    "&:focus": {
      backgroundColor: "transparent",
    },
    "& > input": {
      "&:-internal-autofill-selected": {
        backgroundColor: "transparent !important",
        "-webkit-box-shadow": "0 0 0 50px #f7f8f9 inset",
      },
    },
  },
}))(Input);

const SignIn = ({ isOpen, onClose, showCloseIcon }) => {
  const classes = useStyles();
  const [modalType, setModalType] = useState(MODAL_TYPES.SIGN_IN);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [alertType, setAlertType] = useState(ALERT_TYPES.SUCCESS);
  const [alertMessages, setAlertMessages] = useState([]);
  const [isSignUpSuccess, setIsSignUpSuccess] = useState(false);
  const [signUpErrorMessages, setSignUpErrorMessages] = useState({});
  const [signInErrorMessages, setSignInErrorMessages] = useState({});
  const [resetPasswordErrorMessages, setResetPasswordErrorMessages] = useState(
    {}
  );
  const [isResetPasswordSuccess, setIsResetPasswordSuccess] = useState(false);

  const [formData, setFormData] = useState({
    role: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
    rememberMe: false,
    isSubmitted: false,
    acceptPolicy: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleClickShowPassword = () => {
    setFormData({ ...formData, showPassword: !formData.showPassword });
  };

  const handleClickShowConfirmPassword = () => {
    setFormData({
      ...formData,
      showConfirmPassword: !formData.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (prop) => (event) => {
    setFormData({
      ...formData,
      [prop]: event.target.value,
      isSubmitted: false,
    });
    setSignUpErrorMessages({});
    setSignInErrorMessages({});
  };

  const handleCheckBoxChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.checked });
  };

  const onClickSignInHandler = async () => {
    try {
      setFormData({ ...formData, isSubmitted: true });
      if (formData.email === "" || formData.password === "") return;

      if (!validateEmail(formData.email)) {
        setSignInErrorMessages({ email: "Invalid email" });
        return;
      }

      setIsLoading(true);
      const data = {
        user: {
          email: formData.email,
          password: formData.password,
          remember_me: formData.rememberMe,
        },
      };

      await usersApi.signIn(data);

      localStorage.removeItem("isUserLogout");

      setIsLoading(false);
      window.location.href = "/";
      onClose();
    } catch (error) {
      if (error.errors) {
        setShowSnackBar(true);
        setAlertType(ALERT_TYPES.ERROR);
        setAlertMessages(error.errors);
      }
      if (error.error) {
        setShowSnackBar(true);
        setAlertType(ALERT_TYPES.ERROR);
        setAlertMessages([{ detail: error.error }]);
      }
      setIsLoading(false);
    }
  };

  const onClickSignUpHandler = async () => {
    try {
      setFormData({ ...formData, isSubmitted: true });
      const {
        role,
        firstName,
        lastName,
        email,
        phone,
        password,
        confirmPassword,
      } = formData;

      if (
        role === "" ||
        firstName === "" ||
        lastName === "" ||
        email === "" ||
        phone === "" ||
        password === "" ||
        confirmPassword === ""
      )
        return;

      if (!validatePhone(phone)) {
        setSignUpErrorMessages({ phone: "Invalid phone." });
        return;
      }

      if (!validateEmail(email)) {
        setSignUpErrorMessages({ email: "Invalid email" });
        return;
      }

      setIsLoading(true);
      const data = {
        user: {
          role: role,
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone: phone,
          password: password,
          password_confirmation: confirmPassword,
        },
      };
      await usersApi.signUp(data);
      setIsSignUpSuccess(true);
      setIsLoading(false);
    } catch (error) {
      setSignUpErrorMessages(error);
      setIsLoading(false);
    }
  };

  const onClickSignInWithGoogle = () => {
    window.location = "/users/auth/google_oauth2";
  };

  const onClickSignInWithFacebook = () => {
    window.location = "/users/auth/facebook";
  };

  const resetFormData = () => {
    setFormData({
      role: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
      showPassword: false,
      showConfirmPassword: false,
      rememberMe: false,
      isSubmitted: false,
      acceptPolicy: false,
    });
    setSignUpErrorMessages({});
    setSignInErrorMessages({});
  };

  const onClickUpdateModalType = (type) => {
    resetFormData();
    setModalType(type);
  };

  const handleCloseSnackBar = () => {
    setShowSnackBar(false);
  };

  const onKeyDownSignIn = async (event) => {
    if (event.keyCode === 13) {
      await onClickSignInHandler();
    }
  };

  const onClickResetPasswordHandler = async () => {
    try {
      setFormData({ ...formData, isSubmitted: true });

      if (formData.email === "") return;

      if (!validateEmail(formData.email)) {
        setResetPasswordErrorMessages({ email: ["Invalid email"] });
        return;
      }

      setIsLoading(true);
      const data = {
        user: {
          email: formData.email,
        },
      };
      await usersApi.resetPassword(data);
      setIsResetPasswordSuccess(true);
      setIsLoading(false);
    } catch (error) {
      setResetPasswordErrorMessages(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.signInModal}>
            {!isSignUpSuccess && isBrowser() && showCloseIcon && (
              <div className={classes.header}>
                <Close onClick={onClose}>
                  <CloseIcon />
                </Close>
              </div>
            )}
            {isSignUpSuccess && isMobile() && (
              <div className={classes.header}></div>
            )}
            <Box>
              {!isSignUpSuccess && (
                <div className={classes.logo}>
                  <a href="/" style={{ textDecoration: "none" }}>
                    <h1
                      style={{
                        fontSize: 36,
                        fontWeight: 400,
                        lineHeight: "52px",
                        color: "#000000",
                        textTransform: "uppercase",
                        margin: 0,
                      }}>
                      Swyvel
                    </h1>
                  </a>
                </div>
              )}
              <form className={classes.form}>
                {modalType === MODAL_TYPES.SIGN_IN && (
                  <>
                    <Typography variant={"h3"} className={classes.title}>
                      Sign in
                    </Typography>
                    <Typography className={classes.subTitle}>
                      Ready to go? Let’s get started!
                    </Typography>
                    <FormGroup row>
                      <FormControl
                        fullWidth
                        error={
                          (formData.isSubmitted && formData.email === "") ||
                          signInErrorMessages.email
                        }
                        className={classes.formControl}
                      >
                        <InputLabel
                          htmlFor="email"
                          className={classes.inputLabel}
                        >
                          Email
                        </InputLabel>
                        <CustomInput
                          id="email"
                          type={"email"}
                          value={formData.email}
                          onChange={handleChange("email")}
                          aria-describedby="email-error-text"
                          onKeyDown={onKeyDownSignIn}
                        />
                        {formData.isSubmitted && formData.email === "" && (
                          <FormHelperText id="email-error-text">
                            Please enter your email.
                          </FormHelperText>
                        )}
                        {formData.isSubmitted &&
                          formData.email !== "" &&
                          signInErrorMessages.email && (
                            <FormHelperText id="email-error-text">
                              {signInErrorMessages.email}
                            </FormHelperText>
                          )}
                      </FormControl>
                      <FormControl
                        fullWidth
                        error={formData.isSubmitted && formData.password === ""}
                      >
                        <InputLabel
                          htmlFor="password"
                          className={classes.inputLabel}
                        >
                          Password
                        </InputLabel>
                        <CustomInput
                          id="password"
                          type={formData.showPassword ? "text" : "password"}
                          value={formData.password}
                          onChange={handleChange("password")}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {formData.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          aria-describedby="password-error-text"
                          onKeyDown={onKeyDownSignIn}
                        />
                        {formData.isSubmitted && formData.password === "" && (
                          <FormHelperText id="password-error-text">
                            Please enter your password.
                          </FormHelperText>
                        )}
                      </FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.rememberMe}
                            onChange={handleCheckBoxChange}
                            name="rememberMe"
                            color="default"
                          />
                        }
                        label="Remember Me"
                        className={classes.remember}
                      />
                      <FormControl fullWidth>
                        <SignInButton
                          variant="contained"
                          color="primary"
                          onClick={onClickSignInHandler}
                        >
                          {!isLoading ? (
                            "Sign In"
                          ) : (
                            <CircularProgress
                              disableShrink
                              style={{ color: "#fff" }}
                            />
                          )}
                        </SignInButton>
                      </FormControl>
                      <FormControl fullWidth>
                        <Typography
                          className={classes.newPassword}
                          onClick={() =>
                            onClickUpdateModalType(MODAL_TYPES.RESET_PASSWORD)
                          }
                        >
                          Need new password?
                        </Typography>
                        <span className={classes.or}>Or Sign In</span>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
                          <SignInWithGoogleButton
                            variant="contained"
                            color="primary"
                            onClick={onClickSignInWithGoogle}
                          >
                            <img
                              src={googleIcon}
                              style={{ marginRight: "10px" }}
                            />{" "}
                            With Google
                          </SignInWithGoogleButton>
                          {/* <hr style={{ height: "2px" }} /> */}
                          <SignInWithFacebookButton
                            variant="contained"
                            color="primary"
                            onClick={onClickSignInWithFacebook}
                          >
                            <img
                              src={facebookIcon}
                              style={{ marginRight: "10px" }}
                            />{" "}
                            With Facebook
                          </SignInWithFacebookButton>
                        </div>
                        <hr className={classes.hr} />
                        <SignUpButton
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            onClickUpdateModalType(MODAL_TYPES.SIGN_UP)
                          }
                        >
                          Sign Up
                        </SignUpButton>
                      </FormControl>
                    </FormGroup>
                  </>
                )}
                {modalType === MODAL_TYPES.SIGN_UP && (
                  <>
                    {!isSignUpSuccess ? (
                      <>
                        <Typography variant={"h3"} className={classes.title}>
                          Sign Up
                        </Typography>
                        <Typography className={classes.subTitle}>
                          Please fill in this form to create an account!
                        </Typography>
                        <FormGroup row>
                          <FormControl
                            fullWidth
                            error={formData.isSubmitted && formData.role === ""}
                            className={classes.formControl}
                          >
                            <InputLabel id="role-label">Role</InputLabel>
                            <SelectRole
                              labelId="role-label"
                              value={formData.role}
                              onChange={handleChange("role")}
                              aria-describedby="role-error-text"
                            >
                              <MenuItem value={"instructor"}>
                                Instructor
                              </MenuItem>
                              <MenuItem value={"student"}>Student</MenuItem>
                            </SelectRole>
                            {formData.isSubmitted && formData.role === "" && (
                              <FormHelperText id="role-error-text">
                                Please choose your role.
                              </FormHelperText>
                            )}
                          </FormControl>
                          <FormControl
                            error={
                              formData.isSubmitted && formData.firstName === ""
                            }
                            className={clsx(
                              classes.formControl,
                              classes.firstName
                            )}
                          >
                            <InputLabel
                              htmlFor="firstName"
                              className={classes.inputLabel}
                            >
                              First Name
                            </InputLabel>
                            <CustomInput
                              id="firstName"
                              type={"text"}
                              value={formData.firstName}
                              onChange={handleChange("firstName")}
                              aria-describedby="firstName-error-text"
                              disabled={formData.role === ""}
                            />
                            {formData.isSubmitted &&
                              formData.firstName === "" && (
                                <FormHelperText id="firstName-error-text">
                                  Please enter your first name.
                                </FormHelperText>
                              )}
                          </FormControl>
                          <FormControl
                            error={
                              formData.isSubmitted && formData.lastName === ""
                            }
                            className={clsx(
                              classes.formControl,
                              classes.lastName
                            )}
                          >
                            <InputLabel
                              htmlFor="lastName"
                              className={classes.inputLabel}
                            >
                              Last Name
                            </InputLabel>
                            <CustomInput
                              id="lastName"
                              type={"text"}
                              value={formData.lastName}
                              onChange={handleChange("lastName")}
                              aria-describedby="lastName-error-text"
                              disabled={formData.role === ""}
                            />
                            {formData.isSubmitted &&
                              formData.lastName === "" && (
                                <FormHelperText id="lastName-error-text">
                                  Please enter your last name.
                                </FormHelperText>
                              )}
                          </FormControl>
                          <FormControl
                            fullWidth
                            error={
                              (formData.isSubmitted && formData.phone === "") ||
                              Boolean(signUpErrorMessages.phone)
                            }
                            className={classes.formControl}
                          >
                            <InputLabel
                              htmlFor="phone"
                              className={classes.inputLabel}
                            >
                              Phone
                            </InputLabel>
                            <CustomInput
                              id="phone"
                              type={"text"}
                              value={formData.phone}
                              onChange={handleChange("phone")}
                              aria-describedby="phone-error-text"
                              disabled={formData.role === ""}
                            />
                            {formData.isSubmitted && formData.phone === "" && (
                              <FormHelperText>
                                Please enter your phone.
                              </FormHelperText>
                            )}

                            {formData.isSubmitted &&
                              formData.phone !== "" &&
                              signUpErrorMessages.phone && (
                                <FormHelperText>
                                  {signUpErrorMessages.phone.replace(
                                    "phone",
                                    "Phone"
                                  )}
                                </FormHelperText>
                              )}
                          </FormControl>
                          <FormControl
                            fullWidth
                            error={
                              (formData.isSubmitted && formData.email === "") ||
                              Boolean(signUpErrorMessages.email)
                            }
                            className={classes.formControl}
                          >
                            <InputLabel
                              htmlFor="email"
                              className={classes.inputLabel}
                            >
                              Email
                            </InputLabel>
                            <CustomInput
                              id="email"
                              type={"email"}
                              value={formData.email}
                              onChange={handleChange("email")}
                              aria-describedby="email-error-text"
                              disabled={formData.role === ""}
                            />
                            {formData.isSubmitted && formData.email === "" && (
                              <FormHelperText id="email-error-text">
                                Please enter your email.
                              </FormHelperText>
                            )}
                            {formData.isSubmitted &&
                              formData.email !== "" &&
                              signUpErrorMessages.email && (
                                <FormHelperText id="email-error-text">
                                  {signUpErrorMessages.email.replace(
                                    "email",
                                    "Email"
                                  )}
                                </FormHelperText>
                              )}
                          </FormControl>
                          <FormControl
                            fullWidth
                            error={
                              (formData.isSubmitted &&
                                formData.password === "") ||
                              Boolean(signUpErrorMessages.password)
                            }
                            className={classes.formControl}
                          >
                            <InputLabel
                              htmlFor="password"
                              className={classes.inputLabel}
                            >
                              Password
                            </InputLabel>
                            <CustomInput
                              id="password"
                              type={formData.showPassword ? "text" : "password"}
                              value={formData.password}
                              onChange={handleChange("password")}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {formData.showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              aria-describedby="password-error-text"
                              disabled={formData.role === ""}
                            />
                            {formData.isSubmitted &&
                              formData.password === "" && (
                                <FormHelperText id="password-error-text">
                                  Please enter your password.
                                </FormHelperText>
                              )}
                            {formData.isSubmitted &&
                              formData.password !== "" &&
                              signUpErrorMessages.password && (
                                <FormHelperText id="password-error-text">
                                  {signUpErrorMessages.password.replace(
                                    "password",
                                    "Password"
                                  )}
                                </FormHelperText>
                              )}
                          </FormControl>
                          <FormControl
                            fullWidth
                            error={
                              (formData.isSubmitted &&
                                formData.password === "") ||
                              Boolean(signUpErrorMessages.password_confirmation)
                            }
                          >
                            <InputLabel
                              htmlFor="confirmPassword"
                              className={classes.inputLabel}
                            >
                              Confirm Password
                            </InputLabel>
                            <CustomInput
                              id="confirmPassword"
                              type={
                                formData.showConfirmPassword
                                  ? "text"
                                  : "password"
                              }
                              value={formData.confirmPassword}
                              onChange={handleChange("confirmPassword")}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle confirm password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {formData.showConfirmPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              aria-describedby="confirm-password-error-text"
                              disabled={formData.role === ""}
                            />
                            {formData.isSubmitted &&
                              formData.confirmPassword === "" && (
                                <FormHelperText id="confirm-password-error-text">
                                  Please enter confirm password.
                                </FormHelperText>
                              )}
                            {formData.isSubmitted &&
                              formData.confirmPassword !== "" &&
                              signUpErrorMessages.password_confirmation && (
                                <FormHelperText id="confirm-password-error-text">
                                  {signUpErrorMessages.password_confirmation.replace(
                                    "password_confirmation",
                                    "Password confirmation"
                                  )}
                                </FormHelperText>
                              )}
                          </FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formData.acceptPolicy}
                                onChange={handleCheckBoxChange}
                                name="acceptPolicy"
                                color="default"
                              />
                            }
                            label="I accept the Terms of Use & Privacy Policy."
                            className={classes.remember}
                          />
                          <FormControl fullWidth>
                            <SignInButton
                              variant="contained"
                              color="primary"
                              onClick={onClickSignUpHandler}
                              disabled={!formData.acceptPolicy}
                            >
                              {!isLoading ? (
                                "Sign Up"
                              ) : (
                                <CircularProgress
                                  disableShrink
                                  style={{ color: "#fff" }}
                                />
                              )}
                            </SignInButton>
                          </FormControl>
                          <FormControl fullWidth>
                            <span className={classes.or}>OR</span>
                            <SignUpButton
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                onClickUpdateModalType(MODAL_TYPES.SIGN_IN)
                              }
                            >
                              Sign In
                            </SignUpButton>
                          </FormControl>
                        </FormGroup>
                      </>
                    ) : (
                      <>
                        <Typography variant={"h3"} className={classes.title}>
                          Success!
                        </Typography>
                        <Typography className={classes.subTitle}>
                          Thanks, your account has been successfully created.
                        </Typography>
                        <ImageSignUpSuccess>
                          <img src={signUpSuccessImg} alt="" />
                        </ImageSignUpSuccess>
                        <FormControl fullWidth>
                          <SignInButton
                            variant="contained"
                            color="primary"
                            onClick={onClose}
                          >
                            OK
                          </SignInButton>
                        </FormControl>
                      </>
                    )}
                  </>
                )}
                {modalType === MODAL_TYPES.RESET_PASSWORD && (
                  <>
                    {!isResetPasswordSuccess ? (
                      <>
                        {" "}
                        <IconButton
                          edge="start"
                          className={classes.iconBack}
                          color="inherit"
                          aria-label="menu"
                          size={"small"}
                          onClick={() =>
                            onClickUpdateModalType(MODAL_TYPES.SIGN_IN)
                          }
                        >
                          <ArrowBackIosIcon style={{ fontSize: "14px" }} />
                          <span className={classes.backText}>Back</span>
                        </IconButton>
                        <Typography variant={"h3"} className={classes.title}>
                          Need new password?
                        </Typography>
                        <Typography className={classes.subTitle}>
                          Please enter your email address. You will receive a
                          link to create a new password via email.
                        </Typography>
                        <FormGroup row>
                          <FormControl
                            fullWidth
                            error={
                              formData.isSubmitted &&
                              (formData.email === "" ||
                                resetPasswordErrorMessages.email)
                            }
                            className={classes.formControl}
                          >
                            <InputLabel
                              htmlFor="email"
                              className={classes.inputLabel}
                            >
                              Email
                            </InputLabel>
                            <CustomInput
                              id="email"
                              type={"email"}
                              value={formData.email}
                              onChange={handleChange("email")}
                              aria-describedby="email-error-text"
                            />
                            {formData.isSubmitted && formData.email === "" && (
                              <FormHelperText id="email-error-text">
                                Please enter your email.
                              </FormHelperText>
                            )}
                            {formData.isSubmitted &&
                              resetPasswordErrorMessages.email && (
                                <FormHelperText error>
                                  {resetPasswordErrorMessages.email}
                                </FormHelperText>
                              )}
                          </FormControl>
                          <FormControl fullWidth>
                            <SignInButton
                              variant="contained"
                              color="primary"
                              onClick={onClickResetPasswordHandler}
                              className={classes.sendNow}
                            >
                              {!isLoading ? (
                                "Send Now"
                              ) : (
                                <CircularProgress
                                  disableShrink
                                  style={{ color: "#fff" }}
                                />
                              )}
                            </SignInButton>
                          </FormControl>
                        </FormGroup>
                      </>
                    ) : (
                      <>
                        <Typography variant={"h3"} className={classes.title}>
                          Success!
                        </Typography>
                        <Typography className={classes.subTitle}>
                          Thanks, new password request successful. Please check
                          your email.
                        </Typography>
                        <ImageSignUpSuccess>
                          <img src={signUpSuccessImg} alt="" />
                        </ImageSignUpSuccess>
                        <FormControl fullWidth>
                          <SignInButton
                            variant="contained"
                            color="primary"
                            onClick={onClose}
                          >
                            OK
                          </SignInButton>
                        </FormControl>
                      </>
                    )}
                  </>
                )}
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
      {alertMessages.length > 0 && showSnackBar && (
        <Snackbar
          open={showSnackBar}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleCloseSnackBar}
        >
          <Alert severity={alertType} onClose={handleCloseSnackBar}>
            {alertMessages[0].detail}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

SignIn.prototype = {
  isOpen: bool,
  showCloseIcon: bool,
  onClose: func,
};

SignIn.defaultProps = {
  showCloseIcon: true,
};

export default SignIn;
